<template>
    <div class="row" style="padding: 15px 0.5rem;margin:0px">
        <div class="col-sm-7 left-side">
            <div class="box-top shadow row">
                <div class="col-sm-8">
                    <div class="row box-top-title col-sm-12">Selamat Datang, &nbsp;<b>{{name}}</b></div>
                    <div class="row box-top-desc col-sm-12">Sistem informasi ini dibangun agar Bimbel Taruna Cendekia semakin maju ke depan. Silahkan hubungi admin jika ada kendala atau kesulitan dalam mengoperasikan.</div>
                </div>
                <div class="col-sm-4" style="text-align-last: end;">
                    <img src="@/assets/img/Taruna-maskot-2.png" class="box-top-img" alt="none">
                </div>
            </div>

            <div class="shadow box-item">
                <span style="font-size:18px;color: #363636;">Kalender PR</span>
                <div class="row">
                        <div style="box-shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5);border-radius: 0.5rem;padding: 10px; width: 100%;">
                            <FullCalendar :options="calendarOptions" />
                        </div>
                    
                </div>
            </div>
        </div>
        <div class="col-sm-5" style="padding-left: 24px;">
            <div class="right-side">
                <span style="font-size:18px;color: #363636;">Tool Unit PR</span>
                <div class="row" style="margin-top:12px">
                    <div class="col-sm-6" style="text-align: -webkit-center;padding: 5px;">
                        <router-link to="/siswa-registrasi">
                        <div style="padding:16px 14px 16px 14px;background: #FAFAFA;" class="shadow-low">
                          <i class="fas fa-user fa-2x"></i><br><br> Registrasi Siswa
                        </div>
                        </router-link>
                    </div>
                    <div class="col-sm-6" style="text-align: -webkit-center;padding: 5px;">
                        <router-link to="/kalender-marketing">
                        <div style="padding:16px 14px 16px 14px;background: #FAFAFA;" class="shadow-low">
                          <i class="far fa-calendar-alt fa-2x"></i><br><br> Kalender Marketing
                        </div>
                        </router-link>
                    </div>
                    <div class="col-sm-6" style="text-align: -webkit-center;padding: 5px;">
                        <router-link to="/data-sekolah">
                        <div style="padding:16px 14px 16px 14px;background: #FAFAFA;" class="shadow-low">
                          <i class="fas fa-school fa-2x"></i><br><br> Data Sekolah
                        </div>
                        </router-link>
                    </div>
                    <div class="col-sm-6" style="text-align: -webkit-center;padding: 5px;">
                        <router-link to="/pengajuan-konsultasi">
                        <div style="padding:16px 14px 16px 14px;background: #FAFAFA;" class="shadow-low">
                          <i class="fas fa-clipboard fa-2x"></i><br><br> Pengajuan Konsultasi
                        </div>
                        </router-link>
                    </div>
                    <div class="col-sm-6" style="text-align: -webkit-center;padding: 5px;">
                        <router-link to="/jadwal-konsultasi">
                        <div style="padding:16px 14px 16px 14px;background: #FAFAFA;" class="shadow-low">
                          <i class="fas fa-calendar-alt fa-2x"></i><br><br> Jadwal Konsultasi
                        </div>
                        </router-link>
                    </div>
                    <div class="col-sm-6" style="text-align: -webkit-center;padding: 5px;">
                        <router-link to="/laporan-dinas-luar">
                        <div style="padding:16px 14px 16px 14px;background: #FAFAFA;" class="shadow-low">
                          <i class="fas fa-car fa-2x"></i><br><br> Laporan Dinas Luar
                        </div>
                        </router-link>
                    </div>
                    <div class="col-sm-6" style="text-align: -webkit-center;padding: 5px;">
                        <router-link to="/agenda-kerja">
                        <div style="padding:16px 14px 16px 14px;background: #FAFAFA;" class="shadow-low">
                          <i class="fas fa-list-ol fa-2x"></i><br><br> Agenda Kerja Tim
                        </div>
                        </router-link>
                    </div>
                    <div class="col-sm-6" style="text-align: -webkit-center;padding: 5px;">
                        <router-link to="/proposal-lpj-kegiatan">
                        <div style="padding:16px 14px 16px 14px;background: #FAFAFA;" class="shadow-low">
                          <i class="fas fa-tasks fa-2x"></i><br><br> Proposal dan LPJ Kegiatan
                        </div>
                        </router-link>
                    </div>
                    
                </div>
            </div>
            <div class="right-side">
                <div class="row">
                    <div class="col-sm-6" style="font-size:18px;align-self: center;">Agenda Terdekat</div>
                    <div class="col-sm-6" style="text-align: right;">
                    <router-link class="btn" style="background:#EC008C;color: white;" to="/data-guru-kunjungan">Lihat Semua</router-link>
                    </div>
                </div>
                <div class="row">
                    <div class="shadow box-item">
                        <div class="col-md-12 form-group" style="padding: 0px;" v-if="false">
                            <input id="gender" class="form-control" v-model="form.kbmActivity" type="text" name="i_gender" placeholder="Cari agenda kerja PR" autocomplete="off" />
                        </div>
                        <div class="row">
        <div class="col-sm-12" v-for="row in agenda">
            <div class="callout callout-info">
                <div class="row">
                    <div class="col-sm-8">
                        <h5 style="font-size:14px;font-weight:600;color: #7D7D7D;">{{ row.title }} <br> PIC : {{row.name}}</h5>
                        <p style="font-size:14px;font-weight:400;color: #9590A0;">{{row.tanggal_mulai}} {{row.tanggal_selesai == row.tanggal_mulai ? '' : ' s.d. ' + row.tanggal_selesai}} <br> {{row.jam_mulai}} {{row.jam_selesai == row.jam_mulai ? '' : ' - ' + row.jam_selesai}}</p>
                    </div>
                    <div class="col-sm-4" style="text-align: -webkit-right;">
                        <div class="event-circle">
                            <IconifyIcon :icon="icons.activityFill" :style="{fontSize: '42px', color:'white'}" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="modal-default">
            <div class="modal-dialog">
                <div class="modal-content">
                <form role="form" ref="form">
                    <div class="modal-header">
                        <h4 class="modal-title">Agenda</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p v-if="errors.length" class="alert alert-danger alert-dismissible fade show" role="alert">
                            <b>Please correct the following error(s):</b>
                            <ul>
                                <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                            </ul>
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </p>
                        <div class="col-md-6 form-group">
                            <label class="control-label">Range waktu</label>
                            <input type="hidden" name="row_id" value="" v-model="form.id"/>
                            <input type="hidden" name="created_by" value="" v-model="form.created_by"/>
                            <input type="text" class="form-control" id="daterange" autocomplete="off" disabled>
                        </div>
                        <div class="row" style="padding: 7.5px;">
                            <div class="col-md-6 form-group">
                                <label class="control-label">Mulai</label>
                                <input id="jam_mulai" class="form-control jam_mulai" v-model="form.jam_mulai" type="text" required="required" name="jam_mulai" readonly />
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Selesai</label>
                                <input id="jam_selesai" class="form-control jam_selesai" v-model="form.jam_selesai" type="text" required="required" name="jam_selesai" readonly />
                            </div>
                        </div>
                        <div class="col-md-12 form-group">
                            <label class="control-label">Agenda Kegiatan</label>
                            <input id="agenda" class="form-control agenda" v-model="form.agenda" type="text" required="required" name="agenda" readonly />
                        </div>
                        <div class="col-md-5 form-group">
                            <label class="control-label">Kategory</label>
                            <input id="kategory" class="form-control kategory" v-model="form.kategory" type="text" required="required" name="kategory" readonly />
                        </div>
                        <div class="col-md-4 form-group">
                            <label class="control-label">Person in charge</label>
                            <v-select :options="optMarketing" v-model="form.person" :reduce="opt => opt.id" disabled />
                        </div>
                        <div class="col-md-12 form-group">
                            <label class="control-label">Catatan</label>
                            <textarea class="form-control" v-model="form.catatan" readonly></textarea>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                    </div>
                </form>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
    </div>
</template>

<script type="text/javascript">
    import {auth, authFetch} from '@/libs/hxcore';
    import Calendar from 'color-calendar';
    import "color-calendar/dist/css/theme-glass.css";
    import IconifyIcon from '@iconify/vue';
    import activityFill from '@iconify/icons-eva/activity-fill';
    import FullCalendar from '@fullcalendar/vue'
    import dayGridPlugin from '@fullcalendar/daygrid'
    import interactionPlugin from '@fullcalendar/interaction'
    import Daterangepicker from 'daterangepicker';
    import "daterangepicker/daterangepicker.css";
    import $ from 'jquery';
    import moment from "moment";
    import vSelect from 'vue-select';
    import "vue-select/dist/vue-select.css";

    export default {
        name: 'PRDashboard',
        components: {
            IconifyIcon,
            FullCalendar,
            vSelect,
        },
        data() {
            return {
                icons: {
                    activityFill
                },
                optMarketing: [],
                errors: [],
                agenda : [],
                form: {
                    kbmActivity : '',
                    date:'',
                    start: '',
                    end: '',
                    agenda: '',
                    kategory: '',
                    person: '',
                    catatan: '',
                    jam_mulai:'',
                    jam_selesai:'',
                    created_by: ''
                },
                name : '',
                imgUrl : '',
                calendarOptions: {
                    plugins: [dayGridPlugin, interactionPlugin],
                    initialView: 'dayGridMonth',
                    dateClick: this.handleDateClick,
                    eventClick: this.eventClick,
                    events: [],
                    displayEventTime: false,
                }
            };
        },
        created: function() {
            this.roles = this.$route.meta.roles;
            var info = auth.user();
            console.log('user', info)
            if(info)
            {
              this.name = info.display_name;
              this.imgUrl = info.img_url;
            }
            else{
              this.imgUrl = '/dist/img/user.jpeg';
            }

            authFetch('/pr/kalender_marketing')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.calendarOptions.events = js.data;
            });

            authFetch('/pr/kalender_marketing/marketing')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optMarketing = js.data;
            });
            this.agendaTerdekat();
        },
        methods: {
        agendaTerdekat() {
            authFetch(`/pr/data_guru_kunjungan/agenda`)
            .then((res) => {
            if (res.status === 201) {
            } else if (res.status === 400) {
            }
            return res.json();
            })
            .then((js) => {
                this.agenda = js.data;
            });
        },
        eventClick: function(arg) {
        var self = this;
        authFetch('/pr/kalender_marketing/'+arg.event.id)
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                var s = js.tanggal_mulai.split('-');
                var e = js.tanggal_selesai.split('-');
                this.form.start = s[2]+'/'+s[1]+'/'+s[0];
                this.form.end = e[2]+'/'+e[1]+'/'+e[0];
                this.form.agenda = js.title;
                this.form.kategory = js.label_text;
                this.form.person = js.staff_id_pic;
                this.form.catatan = js.catatan;
                this.form.id = js.id;
                this.form.jam_mulai = js.jam_mulai ? js.jam_mulai.substring(0,5) : ''
                this.form.jam_selesai = js.jam_selesai? js.jam_selesai.substring(0,5) : ''
                this.form.created_by = js.created_by
                $("#modal-default").modal()

                $('#daterange').daterangepicker({ startDate: moment(js.tanggal_mulai), endDate: moment(js.tanggal_selesai) });
                    $('#daterange').on('apply.daterangepicker', function(ev, picker) {
                        self.form.start = picker.startDate.format('DD/MM/YYYY')
                        self.form.end = picker.endDate.format('DD/MM/YYYY')
                    });
            })
        },
        handleDateClick: function(arg) {
            var self = this;
            this.$refs.form.reset();
            this.form.id = '';
            this.form.start = '';
            this.form.end = '';
            this.form.agenda = '';
            this.form.kategory = '';
            this.form.person = '';
            this.form.catatan = '';
            this.form.jam_mulai = '';
            this.form.jam_selesai = '';

            $('input[name="row_id"]').val('')
            
            var date = ('0' + (arg.date.getMonth()+1)).slice(-2)+'/'+('0' + arg.date.getDate()).slice(-2)+'/'+arg.date.getFullYear();
            $('#daterange').daterangepicker({ startDate: date, endDate: date });
            this.form.start = ('0' + arg.date.getDate()).slice(-2)+'/'+('0' + (arg.date.getMonth()+1)).slice(-2)+'/'+arg.date.getFullYear()
            this.form.end = ('0' + arg.date.getDate()).slice(-2)+'/'+('0' + (arg.date.getMonth()+1)).slice(-2)+'/'+arg.date.getFullYear()
            $("#modal-default").modal()

            $('#daterange').on('apply.daterangepicker', function(ev, picker) {
                self.form.start = picker.startDate.format('DD/MM/YYYY')
                self.form.end = picker.endDate.format('DD/MM/YYYY')
            });
        },
        },
        mounted() {
            var self = this;
            new Daterangepicker(this.$refs.daterange, {
            }, function(d1, d2) {
                self.form.start = d1.format('DD/MM/YYYY')
                self.form.end = d2.format('DD/MM/YYYY')
            });
            // new Calendar({
            //   id: "#myCal",
            //   theme: "glass",
            //   eventsData: [
            //         {
            //             start: '2021-07-17T06:00:00',
            //             end: '2021-07-20T20:30:00',
            //             name: 'Blockchain 101'
            //         },
            //         {
            //             start: '2021-07-18T06:00:00',
            //             end: '2021-07-20T20:30:00',
            //             name: '101'
            //         },
            //     ]
            // });
        }
    }
</script>

<style type="text/css" scoped>
    .box-top {
        min-height: 141px;
        background-color: white;
        border-radius: 20px;
        margin-bottom: 32px;
        padding-bottom: 12px;
        padding-top: 12px;
    }

    .box-top-title {
        padding-top: 17px;
        padding-left: 31px;
        min-height: 57px;
        align-content: center;
        font-size: 24px;
    }

    .box-top-desc {
        padding-left: 31px;
        min-height: 57px;
        align-content: center;
        font-weight: 400;
        font-size: 12px;
        color: #7D7D7D;
    }

    .shadow {
        box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 4%) !important;
    }

    .shadow-low {
        box-shadow: 0 0.3rem 0.3rem rgb(18 38 63 / 7%) !important;
    }

    .custom-container {
        padding: 31px 31px 0px 31px;
        height: 100%;
    }

    .items {
        padding-left: 32px;
        padding-top: 25px;
        margin-left: 12px;
        margin-bottom: 21px;
        min-width: 220px;
        background-color: white;
        border-radius: 20px;
        min-height: 210px;
    }

    .items-circle {
        width: 76px;
        height: 76px;
        background: linear-gradient(90deg, #4CB8C4 0%, #3CD3AD 100%);
        border-radius: 50%;
        margin-bottom: 12px;
        text-align: center;
    }

    .items-title {
        height: 40px;
        padding-top : 4px;
        padding-bottom : 4px;
        font-weight: 700;
        font-size: 24px;
        color: #363636;
    }

    .items-desc {
        padding-right: 30px;
    }

    .items-icon {
        transform: translateY(50%);
        -ms-transform: translateY(50%);
    }

    .box-top-img {
    }

    .event-circle {
        width:48px;
        height: 48px;
        background: #C8372D;
        border-radius: 50%;
    }

    /*right side*/
    .right-side {
        background: white;
        border-radius: 10px;
        padding: 26px 19px 12px 22px;
        margin-bottom: 12px;
        /*height: 100%;*/
    }

    #myCal {
        margin-top: 25px;
        margin-bottom: 65px;
        width: 100%;
        text-align-last: center;
    }

    .tool-box {
        /*width:48px;
        height: 48px;*/
        background: #C8372D;
        border-radius: 10px;
    }

    .callout-danger {
        background: #EBEBEB;
    }

    .callout.callout-danger {
        border-left: 8px #C8372D solid;
        border-radius: 10px;
    }

    .callout-warning {
        background: #EBEBEB;
    }

    .callout.callout-warning {
        border-left: 8px #FCA702 solid;
        border-radius: 10px;
    }

    .calendar__body.color-calendar {
        font-size: 0.7rem !important;
    }

    .box-item {
        width:100%;
        min-height: 262px;
        background:white;
        padding: 24px;
        padding-top: 24px;
        border-radius: 20px;
        margin-bottom: 12px;
    }
</style>